<template>
  <div class="pay">
    <Header />

    <div class="pay-banner">
      <img src="@/assets/img/banner1.png" />
      <div class="pay-banner__box">
        <div class="pay-banner__box-title">老铁车帮惠招贤纳士公告</div>
        <p>老铁车帮惠平台长沙维修店商家5月份火热招商加盟上架中，</p>
        <p>加盟费全免！计划招商300家维修店上架，诚邀您的加入！</p>
        <p>加盟热线0731-82232822、18774935656</p>
        <p>投递邮件laotie@laotiechebanghui.com 联系人饶先生</p>
      </div>
    </div>

    <div class="pay-introduce">
      <div class="pay-introduce-top">
        <img src="@/assets/img/dot.png" />
      </div>
      <div class="pay-introduce--box">
        <div class="pay-introduce--box-title">岗位需求</div>
        <div class="pay-introduce--box-form">
          <p style="font-weight: bold; font-size: 18px">
            岗位1：驻站主管 8-15K/月
          </p>
          <p>工作职责：</p>
          <p>1、负责检测站业务对接，团队管理协调和高度工作；</p>
          <p>2、负责APP用户注册推广工作；</p>
          <p>3、负责站点业务周、月、季、年度报表登记汇报；</p>
          <p>4、负责站点区域维修店的拓展与跟进维护工作；</p>
          <p>5、负责站点区域的接车业务派单，管理实施工作；</p>
          <p>任职要求：</p>
          <p>1、持C1以上驾驶证，驾龄3年以上，有车优先；</p>
          <p>2、能吃苦耐劳，有一定的语言表达能力和沟通能力；</p>
          <p>3、具备一定的业务能力，有管理经验者优先；</p>
          <p>4、有敢于担当的精神，有野心胆略者优先。</p>
          <p style="font-weight: bold; font-size: 18px">
            岗位2：业务代表 6-10K/月
          </p>
          <p>工作职责：</p>
          <p>1、配合完成检测站业务对接，以及上门接车业务；</p>
          <p>2、APP用户注册推广工作；</p>
          <p>3、站点区域维修店的拓展维护工作；</p>
          <p>4、听从指挥，配合主管完成站点的相关工作；</p>
          <p>任职要求：</p>
          <p>1、持C1以上驾驶证，驾龄3年以上，有车优先；</p>
          <p>2、能吃苦耐劳，有一定的语言表达能力和沟通能力；</p>
          <p>3、具备一定的业务能力。</p>
          <p style="font-weight: bold; font-size: 18px">
            岗位3：文案策划 5 -6K/月
          </p>
          <p>工作职责：</p>
          <p>1.负责对外宣传稿、新闻稿及软文的撰写；</p>
          <p>2.负责微信、微博等媒体平台的选题策划、文章原创与编辑、更新引流</p>
          <p>3.负责企业文化宣传资料的整理与定期更新相关工作</p>
          <p>4.创作撰写优质内容，快速响应热点话题，熟练撰写不同类型文章；</p>
          <p>任职要求：</p>
          <p>
            1、优秀的文字表现能力，新闻学、传播学、中文、经济管理类相关专业优先；
          </p>
          <p>
            2、悉专业创意方法，思维敏捷，洞察力强，文字功底扎实，语言表达能力强；
          </p>
          <p>
            3、熟悉广告创作流程，有敏锐的创作嗅觉和精准的创意表现力，具备独立思考和分析能力，对问题有自己独特的见解；
          </p>
          <p>4、积极的学习和执行能力，能参与活动执行工作；</p>
          <p>5、有公众号，小程序，抖音，视频号经验者优先。</p>
          <p style="font-weight: bold; font-size: 15px">
            简历投递邮箱：hr@laotiechebanghui.com
          </p>

          <div class="pay-introduce--box-title">招商中心</div>
          <p style="font-weight: bold; font-size: 18px">维修店加盟：</p>
          <p>
            1、平台设定两公里范围内，平台只选择一家固定合作的维修店，且维修店自主经营。
          </p>
          <p>
            2、平台对加盟商家进行品牌化，规范化统一管理，原材料统一进货，具有强大的竞争优势。
          </p>
          <p>3、平台维修店加盟费全免。</p>
          <p style="font-weight: bold; font-size: 18px">汽车配件供应商：</p>
          <p>
            1、长沙市范围内，有意向与平台合作，共同发展的小型汽车配件供货商。
          </p>
          <p>2、有自主配送体系的供应商优先选择。</p>
          <p>3、平台供应商商品上架费用全免。</p>
          <p style="font-weight: bold; font-size: 18px">车务门店：</p>
          <p>1、长沙市范围内，有意向与平台合作车检、代驾、车险等业务。</p>
          <p>2、能够提供接送车服务的优先选择。</p>
          <p>3、平台工号开通上架费用全免。</p>
          <router-link to="about">即刻联系 ></router-link>
          <!-- <div class="pay-introduce--box-form__title">
            姓名<span>*</span>
          </div>
          <div class="pay-introduce--box-form__input">
            <a-input v-model="form.name" />
          </div>
          <div class="pay-introduce--box-form__ps">
            <span v-if="showName">请输入姓名</span>
          </div>

          <div class="pay-introduce--box-form__title">
            电话<span>*</span>
          </div>
          <div class="pay-introduce--box-form__input">
            <a-input v-model="form.phone" />
          </div>
          <div class="pay-introduce--box-form__ps">
            <span v-if="showPhone">请输入有效电话</span>
          </div>

          <div class="pay-introduce--box-form__title">
            邮箱<span>*</span>
          </div>
          <div class="pay-introduce--box-form__input">
            <a-input v-model="form.email" />
          </div>
          <div class="pay-introduce--box-form__ps">
            <span v-if="showEmail">请输入有效邮箱</span>
          </div>

          <div class="pay-introduce--box-form__title">
            公司<span>*</span>
          </div>
          <div class="pay-introduce--box-form__input">
            <a-input v-model="form.company" />
          </div>
          <div class="pay-introduce--box-form__ps">
            <span v-if="showCompany">请输入公司</span>
            
          </div>

          <div class="pay-introduce--box-form__title">
            职务<span>*</span>
          </div>
          <div class="pay-introduce--box-form__input">
            <a-select v-model="form.position" style="width: 100%" size="large">
              <a-select-option v-for="item in selectList" :value="item" :key="item">
                {{item}}
              </a-select-option>
            </a-select>
          
          </div>
          <div class="pay-introduce--box-form__ps">
            <span v-if="showPosition">请选择职务</span>
            
          </div>

          <div class="pay-introduce--box-form__title">
            行业<span>*</span>
          </div>
          <div class="pay-introduce--box-form__input">
            <a-input v-model="form.business" />
          </div>
          <div class="pay-introduce--box-form__ps">
            <span v-if="showBusiness">请输入行业</span>
            
          </div>

          <div class="pay-introduce--box-form__title">
            项目需求概述（可选）
          </div>
          <div class="pay-introduce--box-form__input">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-model="form.note"
            />
          </div>

          <a-button type="primary" block size="large" class="pay-button" @click="getAxios">
            提交
          </a-button> -->
        </div>
        <div v-if="success">
          <a-result
            status="success"
            title="提交成功!"
            sub-title="我们将第一时间与您取得联系。"
          >
            <template #extra>
              <a-button key="console" type="primary" @click="success = false">
                返回
              </a-button>
            </template>
          </a-result>
        </div>
      </div>
    </div>

    <div class="pay-contact">
      <div class="pay-contact__title">扫码下载</div>
      <div class="pay-contact__box">
        <div class="pay-contact__box-item">
          <img src="@/assets/img/xz.png" class="pay-contact__box-item-img" />

          <p>用户端</p>
        </div>
        <div class="pay-contact__box-item">
          <!-- <div class="pay-contact__box-item-right">
            <img src="@/assets/img/gx.png" class="pay-contact__box-item-right-img"/>
            <img src="@/assets/img/er.png" class="pay-contact__box-item-right__er"/>
          </div> -->
          <img src="@/assets/img/ygdxz.png" class="pay-contact__box-item-img" />
          <p>员工端</p>
        </div>
      </div>
    </div>
    <textarea id="3548249324" ref="randomCodeRef" style="display: none">
3548249324</textarea
    >

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";

import axios from "axios";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      selectList: [
        "首席执行官/总经理",
        "首席信息官/首席技术官",
        "信息技术主管/经理",
        "项目经理/市场总监",
        "采购总监/经理",
        "技术工程师",
        "其他",
      ],
      form: {
        name: "",
        phone: "",
        email: "",
        company: "",
        position: "",
        business: "",
        note: "",
      },
      showName: false,
      showPhone: false,
      showEmail: false,
      showCompany: false,
      showPosition: false,
      showBusiness: false,
      success: false,
    };
  },
  methods: {
    copy() {
      let random = this.$refs.randomCodeRef.innerHTML;
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", random);
      input.setAttribute("create", 1);
      input.select();
      document.execCommand("Copy");
      this.$message.success("复制成功");
      var list = document.getElementsByTagName("input");
      var inputList = Array.prototype.slice.call(list);
      inputList.forEach((item) => {
        if (item.getAttribute("create")) document.body.removeChild(item);
      });
    },
    getAxios() {
      let isreturn = false;
      if (!this.form.name) {
        this.showName = true;
        isreturn = true;
      } else {
        this.showName = false;
      }
      if (!this.form.phone) {
        this.showPhone = true;
        isreturn = true;
      } else {
        this.showPhone = false;
      }
      if (!this.form.email) {
        this.showEmail = true;
        isreturn = true;
      } else {
        this.showEmail = false;
      }
      if (!this.form.company) {
        this.showCompany = true;
        isreturn = true;
      } else {
        this.showCompany = false;
      }
      if (!this.form.position) {
        this.showPosition = true;
        isreturn = true;
      } else {
        this.showPosition = false;
      }
      if (!this.form.business) {
        this.showBusiness = true;
        isreturn = true;
      } else {
        this.showBusiness = false;
      }
      if (isreturn) return;
      axios.post("/api/v1/client/commit", this.form).then((res) => {
        console.log(res.data);
        if (res.data.data) {
          this.success = true;
          this.form = {
            name: "",
            phone: "",
            email: "",
            company: "",
            position: "",
            business: "",
            note: "",
          };
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less">
.pay-banner {
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }
}
.pay-banner__box {
  position: absolute;
  top: 10vw;
  left: 50%;
  width: 1200px;
  transform: translate(-50%, 0);
  p {
    margin-bottom: 12px;
    font-size: 26px;
    color: #666666;
    line-height: 20px;
  }
}
.pay-banner__box-title {
  margin-bottom: 30px;
  font-size: 46px;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}
.pay-banner__box--buttton {
  width: 180px;
  height: 60px;
  background: #4a85f9;
  border-radius: 4px;
  font-size: 24px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  cursor: pointer;
}
.pay-introduce {
  width: 1200px;
  margin: 50px auto 100px;
}
.pay-introduce-top {
  margin-left: 60px;
  img {
    width: 86px;
  }
}
.pay-introduce--box {
  padding: 60px 200px;
  box-shadow: 0 0 10px #eee;
}
.pay-introduce--box-title {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #333333;
}
.pay-introduce--box-form {
  width: 560px;
  margin: 50px auto 0;
  font-size: 18px;
}
.pay-introduce--box-form__title {
  line-height: 30px;
  color: #333333;
  span {
    color: #f63840;
  }
}
.pay-introduce--box-form__input {
  input {
    height: 48px;
  }
  .ant-select-lg .ant-select-selection--single {
    height: 48px;
  }
}
.pay-introduce--box-form__ps {
  height: 20px;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  color: #f63840;
}
.pay-button {
  margin-top: 40px;
}
.pay-contact {
  width: 800px;
  margin: 100px auto;
}
.pay-contact__title {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #333333;
}
.pay-contact__box {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.pay-contact__box-item {
  position: relative;
  width: 300px;
  padding: 82px;
  box-shadow: 0 0 10px #eee;
  text-align: center;
  .pay-contact__box-item-img {
    width: 150px;
  }
  p {
    margin: 5px 0 0;
    font-size: 18px;
  }
}
.pay-contact__box-item-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  &:hover .pay-contact__box-item-right__er {
    display: inline;
    animation-name: example;
    animation-duration: 1s;
  }
  &:hover .pay-contact__box-item-right-img {
    display: none;
  }
  .pay-contact__box-item-right-img {
    width: 100px;
  }
}
@keyframes example {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.pay-contact__box-item-right__er {
  position: absolute;
  right: 50px;
  top: 50px;
  width: 200px;
  display: none;
}
.pay-copy {
  cursor: pointer;
  margin-left: 5px;
}
</style>
