<template>
  <div class="about">
    <Header />

    <div class="about-box">
      <div class="about-box__text about-box__text-left">
        <div class="about-box__text-title">
          平台简介
        </div>
        <div class="about-box__text-center">
          老铁车帮惠平台，致力于汽车服务行业，全力打造车后市场全系服务项目，与互联网完美结合，高效、便捷、透明，实惠，确保每一项服务都是价优质好！真正做到省时、省力、省心、省钱！
        
          <br />
          <div class="about-box__text-center">
          独特的返利模式，每单消费，用户返券，推广返币，单单都有返，全员参与，惠及全员。不仅让您养车无忧，并且还能快快乐乐地赚钱。养车用车不花钱还赚钱！
          <br /> </div>
          <div class="about-box__text-center">
          消费、推广、供应链、服务商、员工体系全部融入平台，构建成一个完整的行业生态体系，用户线上下单，线下享受优质的服务，达成客户、供应商、平台和线下服务商的资源共享，多方共赢，助推市场发展的新方向。
    </div>
        </div>
      </div>
      <div class="about-box__img">
        <img src="@/assets/img/tt.png"/>
      </div>
    </div>

    <div class="about-box1">
      <div class="about-box__img about-box__text-right" id="container">
        <!-- <img src="gsjj.png"/> -->
      </div>
      <div class="about-box1__text">
        <div class="about-box__text-title">
          联系方式
        </div>
        <p>
          地址：长沙市天心区芙蓉南路西湖安置小区A区9栋901
        </p>
        <p>
        邮政编码：410000
        </p>
        <p>
        电话：(0731) 82232822
        </p>
        <p>
        电邮：<span>laotie@laotiechebanghui.com</span>
        </p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  mounted() {
    /*eslint-disable*/
    var map = new AMap.Map('container', {
       center:[112.998685,28.040106],
       zoom:16
    });
    var marker = new AMap.Marker({
      position: new AMap.LngLat(112.998685,28.040106),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      title: '老铁车帮惠'
    });
    map.add(marker)

    /*eslint-disable*/
  }
}
</script>

<style lang="less">
.about-title {
  height: 70px;
  width: 100%;
  background: #fff;
}
.about-title__box {
  margin: 0 auto;
  width: 1200px;
  height: 70px;
  display: flex;
  align-items: center;
}
.about-title__box-logo {
  width: 120px;
}
.about-title__box-menu {
  display: flex;
  font-size: 16px;
}
.about-title__box-menu__item {
  width: 90px;
  text-align: center;
  color: #666666;
}
.about-title__seach {
  flex: 1;
  text-align: right;
}
.about-title__call {
  margin: 0 60px;
}
.about-box {
  margin: 82px auto 0;
  display: flex;
  width: 1200px;
}
.about-box1 {
  margin: 82px auto 100px;
  display: flex;
  width: 1200px;
  box-shadow: 0 0 10px #eee;
}
.about-box__img {
  width: 600px;
  height: 400px;
  img {
    width: 600px;
  }
}
.about-box__text {
  width: 600px;
  span {
    color: #4A85F9;
  }
}
.about-box1__text {
  width: 600px;
  padding: 104px 0 0;
  span {
    color: #4A85F9;
  }
  p {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
}
.about-box__text-title {
  margin-bottom: 24px;
  font-size: 40px;
  font-weight: 500;
  color: #333333;
}
.about-box__text-center {
  text-indent: 2em;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
}
.about-box__text-left {
  margin-right: 48px;
}
.about-box__text-right {
  margin-right: 48px;
}
</style>